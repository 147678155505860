import { InfoOutlined } from '@mui/icons-material';
import React, { RefObject } from "react";
import styled from "styled-components";

import Customer from "../models/Customer";
import CustomerService from "../services/CustomerService";

type Props = {

}

type State = {
    customer?: Customer | null,
    widgetConfiguration?: any | null
}

const StyledServiceInformation = styled.div`
    width: 100%;
    margin-bottom: 80px;
    font-size: 21px;
    font-size: 1.3125rem;

    @media screen and (max-width: ${props => props.theme.breakpointMobile}) {
        & {
            padding: 0 25px;
            font-size: 18px;
            font-size: 1.125rem;
        }
    }
`;

const StyledContainer = styled.div`
    padding: 0 22.5% 0 12.5%;
`;

const StyledDescription = styled.div`
    color: ${props => props.theme.accent};
    cursor: pointer;
    display: inline-block;
    user-select: none;
    font-size: 1.3125rem;
    display: flex;
    align-items: center;

    svg {
        margin: 0 20px 0 0;
        vertical-align: middle;
    }
`;

const StyledContent = styled.p`
    color: ${props => props.theme.grey02};
    padding: 25px 0 0;
    height: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    @media screen and (max-width: ${props => props.theme.breakpointMobile}) {
        & {
            font-size: 18px;
        }
    }
`;

export default class ServiceInformation extends React.Component<Props, State> {

    contentRef: RefObject<HTMLParagraphElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            customer: null,
            widgetConfiguration: null
        };
        this.contentRef = React.createRef();
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    async componentDidMount() {
        const customer = await CustomerService.getCustomer();
        if (customer === null) {
            return;
        }
        const widgetConfiguration = JSON.parse(customer?.widgetConfig);
        this.setState({
            widgetConfiguration: widgetConfiguration,
            customer: customer
        });
    }

    handleOnClick(e: any) {
        e.preventDefault();
        const content = this.contentRef.current;
        if (content === null) {
            return;
        }
        const scrollHeight: number = content.scrollHeight;
        const height: number = Number.parseInt(content.style.height);
        content.style.height = height === 0 || !height ? scrollHeight + 'px' : '0px';
    }

    render() {
        const {
            customer,
            widgetConfiguration
        } = this.state;
        
        return (
            <StyledServiceInformation>
                <StyledContainer>
                    {customer && customer.publicDescription && <div>
                        <StyledDescription onClick={this.handleOnClick}>
                            <InfoOutlined fontSize="large" />
                            Mehr Information zu diesem Service
                        </StyledDescription>
                        <StyledContent ref={this.contentRef}>
                            {customer.publicDescription}
                        </StyledContent>
                    </div>}
                </StyledContainer>
            </StyledServiceInformation>
        );
    }
}