import Role from '../models/Role'

export default abstract class RoleService {
  static async getSortedRolesByCustomer(customerId: number) {
    const response = await fetch(process.env.REACT_APP_API_URL + '/api/customers/' + process.env.REACT_APP_CUSTOMER)

    if (response.status !== 200) {
      return null // customer not found
    }

    const json = await response.json()
    const customerData = json.customers
    const roles: Role[] = []

    customerData.roles.forEach((item: any) => {
      const role = new Role()
      role.id = item.id
      role.name = item.name
      role.nameExtern = item.name_extern ?? item.name
      role.short = item.short
      role.visibleextern = item.visibleextern

      roles.push(role)
    })

    return roles
  }
}
