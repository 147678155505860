const baseTheme = {
  white: '#ffffff',
  grey00: '#f7f7f7',
  grey01: '#e9e9e9',
  grey02: '#706f6f',
  grey03: '#a9a8a8',
  grey04: '#101010',
  grey05: '#f0f0f0',
  grey06: '#191919',
  grey07: '#eeeeee',
  grey08: '#c6c5c5',
  grey09: '#e9eef1',
  black: '#000',
  blue: '#235677',
  breakpointTablet: '1280px',
  breakpointMobile: '900px',
}

const theme = {
  ...baseTheme,
  ...(process.env.REACT_APP_TEMPLATE_KEY === 'charm'
    ? {
        accent: '#008dce',
        accentHover: '#008dced0',
        accentHoverLight: '#008dce10',
      }
    : {
        accent: '#dc0000',
        accentHover: '#dc0000d0',
        accentHoverLight: '#dc000010',
      }),
}

export default theme
