import React from 'react';
import styled from 'styled-components';

const StyledCookieConsent = styled.div<any>`
    display: ${props => props.show ? 'block' : 'none'};
    bottom: 0;
    z-index: 999999;
    max-width: 100vw;
    color: ${props => props.theme.black};
    font-size: 18px;
    font-size: 1.125rem;
    height: auto;
    position: fixed;
    background-color: ${props => props.theme.grey09};
    margin: 12px;
`;

const StyledCookieConsentWrapper = styled.div`
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    padding: 3vh 10vw;
`;

const StyledCookieConsentMessage = styled.div`
    width: 80%;
    padding-right: 20px;
    line-height: 1.25;
`;

const StyledLink = styled.a`
    text-transform: uppercase;
    color: ${props => props.theme.black};
    text-decoration: none!important;

    &:hover {
        text-decoration: underline!important;
    }
`;

const StyledButtonContainer = styled.div`
    width: 20%;
`;

const StyledButton = styled.button`
    background-color: transparent;
    color: ${props => props.theme.black};
    font-size: 13px;
    font-size: .8125rem;
    text-align: center;
    border: 1px solid ${props => props.theme.black};
    transition: .5s all ease-in-out;
    margin: 0;
    height: 40px;
    width: 100%;  
    border-radius: 3px;
    padding: 0px;
    cursor: pointer;

    &:hover {
        box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
    }
`;

type Props = {

};

type State = {
    show: boolean
};

export default class CookieConsent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            show: true
        };
        this.consent = this.consent.bind(this);
    }

    componentDidMount() {
        if (document.cookie.includes("cookie_consent")) {
            this.setState({ show: false });
        }
    }

    setCookie(name: string, value: any, expirationInDays: number) {
        const date = new Date();
        date.setTime(date.getTime() + expirationInDays * 24 * 60 * 60 * 1000);
        document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + ';path=/';
    }

    consent() {
        this.setCookie('cookie_consent', 1, 365 * 20);
        this.setState({ show: false });
    }

    render() {
        const { show } = this.state;
        return (
            <StyledCookieConsent show={show}>
                <StyledCookieConsentWrapper>
                    <StyledCookieConsentMessage>
                        Wir würden gerne Cookies verwenden, um die Präferenzen unserer Nutzer zu erkennen und diese Website optimal
                        gestalten zu können. Detaillierte Informationen und wie Sie Ihre Einwilligung jederzeit widerrufen können,
                        erhalten Sie in unseren <StyledLink href="https://www.schindler.com/de/internet/de/system/disclaimer-navigation/cookie-richtlinie.html">Cookie-Richtlinien</StyledLink>
                    </StyledCookieConsentMessage>
                    <StyledButtonContainer>
                        <StyledButton onClick={this.consent}>Ok</StyledButton>
                    </StyledButtonContainer>
                </StyledCookieConsentWrapper>
            </StyledCookieConsent>
        );
    }
}