import React, { ChangeEventHandler, ReactEventHandler, ReactNode } from "react";
import styled from 'styled-components';

type Props = {
    placeholder?: string,
    onChange: ReactEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    required?: boolean,
    type: 'text' | 'email' | 'tel' | 'number' | 'textarea' | 'checkbox'
    name: string,
    label?: string
};

const StyledInput = styled.input`
    border:none;
    border-bottom: 1px solid ${props => props.theme.grey01};
    padding: 12px;
    margin: 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    width: 100%;
    box-sizing: border-box;

    &:focus {
        outline: 0;
        border-bottom: 2px solid ${props => props.theme.accent};
    }
`;

const StyledTextarea = styled.textarea`
    border: none;
    border-bottom: 1px solid ${props => props.theme.grey01};
    padding: 12px;
    margin: 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    width: 100%;
    resize: vertical;

    &:focus {
        outline: 0;
        border-bottom: 2px solid ${props => props.theme.accent};
    }
`;

const StyledLabel = styled.label<any>`
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    color: ${props => props.dark ? props.theme.black : props.theme.grey02};
    display: block;

    a {
        color: ${props => props.theme.accent};
    }
`;

const StyledCheckboxContainer = styled.label`
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input:checked ~ .checkmark:after {
        display: block;
    }

    input:checked ~ .checkmark {
        border-color: ${props => props.theme.grey04};
    }
`;

const StyledCheckbox = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`;

const StyledCheckmark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: ${props => props.theme.white};
    border: 1px solid ${props => props.theme.grey01};
    transition: border-color 0.3s ease-in-out;
    border-radius: 3px;

    &:hover {
        border-color: ${props => props.theme.grey04};
    }

    &:after {
        content: "";
        position: absolute;
        display: none;
        left: 6px;
        top: -2px;
        width: 10px;
        height: 20px;
        border: solid ${props => props.theme.grey04};
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

export default class Input extends React.Component<Props> {

    render() {
        const {
            placeholder,
            onChange,
            required,
            name,
            label,
            type
        } = this.props;

        if (type === 'textarea') {
            return (
                <div>
                    {label &&
                        <StyledLabel>{label}{required && " *"}</StyledLabel>}
                    <StyledTextarea name={name} placeholder={placeholder} required={required} onChange={onChange}></StyledTextarea>
                </div>
            );
        } else if (type === 'checkbox') {
            return (
                <div>
                    {label &&
                        <StyledCheckboxContainer>
                            <StyledCheckbox type={type} name={name} placeholder={placeholder} required={required} onChange={onChange} />
                            <StyledCheckmark className="checkmark"></StyledCheckmark>
                            <StyledLabel dark style={{ paddingTop: '3px' }} dangerouslySetInnerHTML={{ __html: label + (required ? '*' : '') }}></StyledLabel>
                        </StyledCheckboxContainer>}
                </div>
            );
        } else {
            return (
                <div>
                    {label &&
                        <StyledLabel dangerouslySetInnerHTML={{ __html: label + (required ? '*' : '') }}></StyledLabel>}
                    <StyledInput type={type} name={name} placeholder={placeholder} required={required} onChange={onChange} />
                </div>
            );
        }
    }
}