import IframeStoerungsmeldung from "../components/IframeStoerungsmeldung";
import RegionSearch from "../components/RegionSearch";
import ServiceInformation from "../components/ServiceInformation";
import SubHeader from "../components/SubHeader";

const Home = () => {
    return (<div>
        <SubHeader />
        <ServiceInformation />
        <IframeStoerungsmeldung />
        <RegionSearch />
    </div>)
};

export default Home;