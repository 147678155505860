import { Facebook, Instagram, KeyboardArrowRight, Twitter, YouTube } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';

import SenkrechtStartetImage from '../assets/images/senkrechtstarter.jpeg';

const StyledFooter = styled.footer`
    width: 100%;
`;

const StyledGrid = styled.div`
    margin: 220px 12% 50px 12.5%;
    display: grid;
    grid-template-columns: 15% 25% 25% 35%;
    color: ${props => props.theme.grey02};
    grid-gap: 20px;
    font-size: 16px;
    font-size: 1rem;

    @media screen and (max-width: ${props => props.theme.breakpointMobile}) {
        & {
            justify-items: flex-start;
            grid-template-columns: 1fr;
            grid-row-gap: 20px;
        }
    }
`


const StyledLogoCol = styled.div`
    
`;

const StyledAddressCol = styled.div`
    
`;

const StyledSocialCol = styled.div`
    
`;

const StyledLinkCol = styled.div`
    ul {
        padding: 0;
        margin: 0;

        li {
            list-style-type: none;
        }
    }
`;

const StyledLink = styled.a`
    color: ${props => props.theme.grey02};
    text-decoration: none!important;
    display: flex;
    align-items: center;

    &:hover {
        color: ${props => props.theme.blue};
    }
`;

const StyledSocialLink = styled.a`
    display: inline-block;
    padding-right: 0.8rem;
    color: ${props => props.theme.grey02};
    text-decoration: none!important;

    &:hover {
        color: ${props => props.theme.accent};
    }
`;

const StyledLogo = styled.img`
    height: 40px;
    width: auto;
    vertical-align: bottom;
`;

const Footer = () => {
    return (
        <StyledFooter>
            <StyledGrid>   
                <StyledLogoCol>{process.env.REACT_APP_COMPANY} &copy; {(new Date()).getFullYear()}</StyledLogoCol>
                <StyledAddressCol dangerouslySetInnerHTML={{ __html:  process.env.REACT_APP_COMPANY_ADDRESS || "" }}>
                </StyledAddressCol>
                <StyledLinkCol>
                    <ul>
                        <li>
                            <StyledLink href={process.env.REACT_APP_AGB}>
                                <KeyboardArrowRight />
                                AGB
                            </StyledLink>
                        </li>
                        <li>
                            <StyledLink href={process.env.REACT_APP_PRIVACY}>
                                <KeyboardArrowRight />
                                Datenschutzerklärung
                            </StyledLink>
                        </li>
                        <li>
                            <StyledLink href={process.env.REACT_APP_COOKIE_POLICY}>
                                <KeyboardArrowRight />
                                Cookie Richtlinien
                            </StyledLink>
                        </li>
                        <li>
                            <StyledLink href={process.env.REACT_APP_IMPRINT}>
                                <KeyboardArrowRight />
                                Impressum
                            </StyledLink>
                        </li>
                        
                    </ul>
                </StyledLinkCol>
                <StyledSocialCol>
                    {
                        process.env.REACT_APP_TEMPLATE_KEY === 'schirm' && (             
                            <div>
                                Folgen Sie uns:<br />
                                <StyledSocialLink href="http://www.facebook.com/schindlerdeutschland" target="_blank">
                                    <Facebook color="inherit" />
                                </StyledSocialLink>
                                <StyledSocialLink href="http://twitter.com/SchindlerDE" target="_blank">
                                    <Twitter color="inherit" />
                                </StyledSocialLink>
                                <StyledSocialLink href="https://www.instagram.com/schindler_de/" target="_blank">
                                    <Instagram color="inherit" />
                                </StyledSocialLink>
                                <StyledSocialLink href="http://www.youtube.com/user/SchindlerDeutschland?sub_confirmation=1" target="_blank">
                                    <YouTube color="inherit" />
                                </StyledSocialLink>
                                <StyledSocialLink href="https://magazin.schindler.de/" target="_blank">
                                    <StyledLogo src={SenkrechtStartetImage} />
                                </StyledSocialLink>
                            </div>
                        )
                    }
       
                </StyledSocialCol>
            </StyledGrid>
        </StyledFooter>
    );
};

export default Footer;