import React from "react";
import styled from "styled-components";

import Customer from "../models/Customer";
import CustomerService from "../services/CustomerService";
import Button from "./Button";

type Props = {

}

type State = {
    customer?: Customer | null,
    widgetConfiguration?: any | null
}

const StyledButtonGroup = styled.div`
    margin-top: 50px;
    display: flex;
    gap: 30px;

    @media screen and (max-width: ${props => props.theme.breakpointMobile}) {
        & {
            flex-flow: column;
            gap: 0;
        }
    }
`;

export default class ButtonGroup extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            customer: null,
            widgetConfiguration: null
        };
    }

    async componentDidMount() {
        const customer = await CustomerService.getCustomer();
        if (customer === null) {
            return;
        }
        const widgetConfiguration = JSON.parse(customer?.widgetConfig);
        this.setState({
            widgetConfiguration: widgetConfiguration,
            customer: customer
        });
    }

    render() {
        const {
            customer,
            widgetConfiguration
        } = this.state;

        return (
            <StyledButtonGroup>
                {widgetConfiguration && widgetConfiguration.malfunction &&
                    <Button label="Störungsmeldung" type="button" onClick={() => { }} />}
                {widgetConfiguration && widgetConfiguration.malfunction &&
                    <Button grey={true} label="Ansprechpartner:in finden" type="button" onClick={() => { }} />}
            </StyledButtonGroup>
        );
    }
}