import React from "react";
import styled from "styled-components";

import Customer from "../models/Customer";
import CustomerService from "../services/CustomerService";
import ButtonGroup from "./ButtonGroup";
import { Heading } from "./Heading";

type Props = {

}

type State = {
    customer?: Customer | null,
    widgetConfiguration?: any | null
}

const StyledSubHeaderWrapper = styled.div`
    padding: 0 22.5% 45px 12.5%;

    @media screen and (max-width: ${props => props.theme.breakpointMobile}) {
        & {
            padding: 0 25px;
        }
    }
`;

const StyledText = styled.p`
    color: ${props => props.theme.grey02};
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.15;
    margin-bottom: 0 0 50px 0;

    @media screen and (max-width: ${props => props.theme.breakpointMobile}) {
        & {
            font-size: 20px;
            font-size: 1.25rem;
            line-height: 25px;
        }
    }
`;

export default class SubHeader extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            customer: null,
            widgetConfiguration: null
        };
    }

    async componentDidMount() {
        const customer = await CustomerService.getCustomer();
        if (customer === null) {
            return;
        }
        const widgetConfiguration = JSON.parse(customer?.widgetConfig);
        this.setState({
            widgetConfiguration: widgetConfiguration,
            customer: customer
        });
    }

    render() {
        const {
            customer,
            widgetConfiguration
        } = this.state;

        return (
            <StyledSubHeaderWrapper>
                {widgetConfiguration && widgetConfiguration.title &&
                    <Heading kind="h1">{widgetConfiguration.title}</Heading>}
                {widgetConfiguration && widgetConfiguration.text &&
                    <StyledText>{widgetConfiguration.text}</StyledText>}
                <ButtonGroup />
            </StyledSubHeaderWrapper>
        );
    }
}