import React from "react";
import styled from "styled-components";

import Customer from "../models/Customer";
import CustomerService from "../services/CustomerService";

const StyledSection = styled.section`
    width: 100vw;
 
    margin-bottom: 40px;
    border: 0;
    display: block;
    border: 0;
    display: block;
`;

const StyledContainer = styled.div`
    margin: 0;
`;

const StyledIframe = styled.iframe`
    margin-top: 20px;
    border: 2px solid ${(props) => props.theme.white};
    padding-left: 0;
`;

type Props = {

};

type State = {
    widgetConfiguration?: any | null,
    customer?: Customer | null
}

export default class IframeStoerungsmeldung extends React.Component<Props, State> {

    iframeRef: React.RefObject<HTMLIFrameElement>;

    constructor(props: any) {
        super(props);
        this.iframeRef = React.createRef();
        this.state = {
            widgetConfiguration: null,
            customer: null
        };
    }

    resizeIframe(height: any) {
        const iframeDisorder = this.iframeRef.current;
        if (iframeDisorder === null) {
            return;
        }
        if (height > 0) {
            iframeDisorder.style.height = height + 'px';
        }

    }

    async componentDidMount() {
        const customer = await CustomerService.getCustomer();
        if (customer != null) {
            const widgetConfiguration = JSON.parse(customer.widgetConfig);
            this.setState({
                customer: customer,
                widgetConfiguration: widgetConfiguration
            });
        }

        const iframeDisorder = this.iframeRef.current;
        if (iframeDisorder === null) {
            return;
        }

        // Create IE + others compatible event handler
        const eventer = window.addEventListener;
        eventer(
            'message',
            (e) => {
                console.log('parent received message!:  ', e);
                
                if (e.origin !== 'https://stoerungsmeldung.haushahn.de') {
                    return;
                }
                this.resizeIframe(e.data.height);
            },
            false
        );
    }

    render() {

        const { widgetConfiguration } = this.state;

        if (widgetConfiguration !== null && !widgetConfiguration.malfunction) {
            return <div></div>;
        }

        return (
            <StyledSection id="stoerungsmeldung">
                <StyledContainer>
                    <StyledIframe ref={this.iframeRef} src={process.env.REACT_APP_IFRAME_URL} width="100%" height="800"></StyledIframe>
                </StyledContainer>
            </StyledSection>
        );
    }
}
