export default class Customer {
  id: string = ''
  name: string = ''
  short: string = ''
  alias: string = ''
  logo: string = ''
  image: string = ''
  publicName = ''
  publicDescription = ''
  widgetConfig = ''
}
