import Contact from './Contact'
import Region from './Region'
import Role from './Role'

export default class RegionRole {
  id: number = 0
  createdAt: string = ''
  updatedAt: string = ''
  region: Region | null = null
  contact: Contact | null = null
  role: Role | null = null
  rolePublicName: string = ''
  roleLevel: number = 0
}
