import React, { ChangeEventHandler } from "react";
import styled from 'styled-components';

type Props = {
    onClick: React.MouseEventHandler,
    label: string,
    outlined?: boolean,
    grey?: boolean,
    type?: "submit" | "button" | undefined,
    textSize?: 'small' | 'large'
};

type State = {

};

const StyledButton = styled.button<any>`
    background-color: ${props => props.theme.accent};
    color: ${props => props.theme.white};
    font-size: ${props => props.textSize === 'small' ? '16px' : '1.3125rem'};
    text-align: center;
    letter-spacing: 0;
    line-height: 30px;
    margin: 0 0 30px 0;
    padding: 15px 40px;
    border: none;
    cursor: pointer;
    transition: box-shadow 250ms cubic-bezier(.4,0,.2,1);
    border-radius: 3px;

    &:hover {
        box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
    }
`;

const StyledButtonOutlined = styled(StyledButton)`
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.accent};
    border: 1px solid ${props => props.theme.accent};

    &:hover {
        box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
    }
`;

const StyledButtonGrey = styled(StyledButton)`
    background-color: ${props => props.theme.grey05};
    color: ${props => props.theme.grey06};

    &:hover {
        box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
    }
`;


export default class Button extends React.Component<Props, State> {

    state: State = {};

    render() {
        const {
            label,
            onClick,
            outlined,
            grey,
            type,
            textSize
        } = this.props;

        if (outlined) {
            return (
                <StyledButtonOutlined textSize={textSize} type={type ?? 'button'} onClick={onClick}>
                    {label}
                </StyledButtonOutlined>
            );
        } else if (grey) {
            return (
                <StyledButtonGrey textSize={textSize} type={type ?? 'button'} onClick={onClick}>
                    {label}
                </StyledButtonGrey>
            )
        } else {
            return (
                <StyledButton textSize={textSize} type={type ?? 'button'} onClick={onClick}>
                    {label}
                </StyledButton>
            );
        }
    }
}