import React from 'react'
import { useParams } from 'react-router-dom'

import LeadForm from '../components/LeadForm'
import { useConfig } from '../contexts/config'

const LeadFormWrapper = () => {
  const { zipId, regionRoleId, kind } = useParams()
  const config = useConfig()

  return (
    <LeadForm url={config.url} zipId={zipId as string} regionRoleId={regionRoleId as string} kind={kind as string} />
  )
}

export default LeadFormWrapper
