import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import CookieConsent from './components/CookieConsent';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';
import LeadFormWrapper from './pages/LeadFormWrapper';
import themeData from './theme';

type State = {

}

type Props = {

};

class App extends React.Component<Props, State> {

  render() {
    return (
      <ThemeProvider theme={themeData}>
        <BrowserRouter basename={process.env.REACT_APP_CUSTOMER}>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/form/:zipId/:regionRoleId/:kind" element={<LeadFormWrapper />} />
          </Routes>
          <Footer />
          <CookieConsent />
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;
