import Customer from '../models/Customer'

export default abstract class CustomerService {
  static customer?: Customer | null = null

  static async getCustomer() {
    if (CustomerService.customer != null) {
      return CustomerService.customer
    }

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/customers/' + process.env.REACT_APP_CUSTOMER)

    if (response.status !== 200) {
      return null // customer not found
    }

    const json = await response.json()
    const customerData = json.customers

    const customer = new Customer()
    customer.id = customerData.id
    customer.alias = customerData.alias
    customer.image = customerData.image
    customer.logo = customerData.logo
    customer.name = customerData.name
    customer.publicDescription = customerData.public_description
    customer.publicName = customerData.public_name
    customer.short = customerData.short
    customer.widgetConfig = customerData.widget_config

    CustomerService.customer = customer

    return customer
  }
}
