import styled from 'styled-components';

type Props = {
    kind: "h1" | "h2" | "h3",
    children: any
};

const StyledHeading1 = styled.h1<any>`
    font-size: 3.375rem;
    margin: 0 0 30px;
    line-height: 1.15;
    letter-spacing: .2px !important;
    color: ${props => props.theme.accent};

    @media screen and (max-width: ${props => props.theme.breakpointMobile}) {
        & {
            font-size: 24px;
            font-size: 1.5rem;
            margin: 0 0 10px;
        }
    }
`;

const StyledHeading2 = styled.h2<any>`
    font-size: 32px;
    line-height: 40px;
    color: ${props => props.theme.accent};
    font-weight: 500;

    @media screen and (max-width: ${props => props.theme.breakpointMobile}) {
        & {
            margin: 0 0 10px;
        }
    }
`;

const StyledHeading3 = styled.h3<any>`
    font-size: 24px;
    line-height: 36px;
    color: ${props => props.theme.black};
    font-weight: 300;
    letter-spacing: .2px;
`;

export const Heading = ({ kind, children }: Props) => {
    if (kind === 'h2') {
        return <StyledHeading2>{children}</StyledHeading2>
    } else if (kind === 'h1') {
        return <StyledHeading1>{children}</StyledHeading1>
    } else {
        return <StyledHeading3>{children}</StyledHeading3>
    }
}