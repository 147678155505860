import React, { ChangeEventHandler } from "react";
import styled from 'styled-components';

import Button from "./Button";
import { Heading } from "./Heading";
import Input from "./Input";

type Props = {
    question: String,
    onSubmit: Function
};

type State = {
    result: String
};

const StyledCaptcha = styled.div`
    padding: 20px;
    background-color: ${props => props.theme.grey00};
`;

const StyledLabel = styled.label`
    display: block;
    margin: 20px 0px;
`;

const StyledInputWrapper = styled.div`
    display: flex;
    gap: 20px;

    @media screen and (max-width: ${props => props.theme.breakpointMobile}) {
        & {
            flex-direction: column;
        }
    }
`;

export default class Captcha extends React.Component<Props, State> {

    state: State = {
        result: ""
    };

    constructor(props: Props) {
        super(props);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.handleOnInputChange = this.handleOnInputChange.bind(this);
    }

    handleOnSubmit(e: any) {
        e.preventDefault();

        const { result } = this.state;

        if (result) {
            this.props.onSubmit(result);
        }
    }

    handleOnInputChange(e: any) {
        this.setState({
            result: e.target.value
        });
    }

    render() {
        const {
            question,
            onSubmit
        } = this.props;

        return (
            <StyledCaptcha>
                <Heading kind="h2">Prüfung der Anfrage</Heading>
                <p>
                    Zum Schutz unserer Daten müssen wir sicherstellen, dass Sie ein realer Nutzer sind. Bitte lösen Sie hierzu die folgende Frage:<br />
                    <StyledLabel>{question}</StyledLabel>
                    <StyledInputWrapper>
                        <Input name="result" type="text" onChange={this.handleOnInputChange} />
                        <Button label={'Senden'} onClick={this.handleOnSubmit} />
                    </StyledInputWrapper>
                </p>
            </StyledCaptcha>
        );
    }
}