import React, { ChangeEventHandler, RefObject } from "react";
import styled from 'styled-components';

import arrow from '../assets/icons/arrow.svg';

type Props = {
    onChange: ChangeEventHandler<HTMLInputElement>,
    selected?: { label: string, value: any } | null,
    onTriggerClicked: Function,
    placeholder: string,
    entries: { label: string, value: any }[],
    onSelect: Function,
    open: boolean
};

type State = {
    term: string
};

const PowerSelectContainer = styled.div`
    max-width: 600px;
    margin-bottom: 20px;
    position: relative;
`;

const SelectTrigger = styled.div<any>`
    border-bottom: ${props => props.display ? '2px' : '1px'} solid ${props => props.display ? props.theme.accent : props.theme.grey01};
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: ${(props) => props.theme.white};

    &:active {
        border-bottom: 2px solid ${props => props.theme.accent};
    }
`;

const Placeholder = styled.span<any>`
    color: ${props => props.theme.black};
    font-weight: ${props => props.selected ? 500 : 300};
    font-size: 16px;
    cursor: inherit;
`;

const Dropdown = styled.div<any>`
    display: ${props => props.display ? 'block' : 'none'};
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    max-height: 250px;
    line-height: 1.75;
    overflow: hidden;
    color: inherit;
    position: absolute;
    width: calc(100% - 2px);
    top: 50px;
    left: 0;
    background-color: ${props => props.theme.white};
`;

const StatusIcon = styled.i<any>`
    transform: rotate(${props => props.display ? '180deg' : '0deg'});
    cursor: inherit;
    background-image: url("${props => props.icon}");
    width: 24px;
    height: 24px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    transition: all .3s ease-in-out;
    margin-right: 15px;
`;

const SearchInputField = styled.input`
    padding: 5px 5px;
    margin: 4px;
    width: calc(100% - 8px);
    font-weight: 300;
    font-size: 16px;
    line-height: 1.75;
    border: none;

    &::placeholder {
        color: #676361;
    }

    &:active,
    &:focus {
        outline: none;
    }
`;

const ResultList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0px;
    user-select: none;
    max-height: 12.25em;
    overflow-y: auto;
`;

const ResultEntry = styled.li`
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    padding: 4px 8px;
    cursor: pointer;
    transition: color .3s ease-in-out;  
    line-height: 1.75;

    &:hover {
        background-color: ${props => props.theme.grey01};
        color: ${props => props.theme.black};
        border-color: ${props => props.theme.grey03};
    }
`;

export default class Select extends React.Component<Props, State> {
    state: State = {
        term: '',
    };

    inputRef: RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);
        this.handleOnTriggerClick = this.handleOnTriggerClick.bind(this);
        this.handleOnInputChange = this.handleOnInputChange.bind(this);
        this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.inputRef.current?.addEventListener("keydown", this.handleOnKeyDown);
    }

    handleOnTriggerClick = (e: any) => {
        this.props.onTriggerClicked();
        setTimeout(() => {
            this.inputRef.current?.focus();
        }, 250);
    }

    handleOnInputChange(e: any) {
        this.setState({
            term: e.target.value,
        });
        this.props.onChange(e);
    }

    handleOnKeyDown(e: any) {
        const {
            entries,
            onSelect
        } = this.props;
        if (e.key === 'Enter' && entries.length > 0) {
            e.preventDefault();
            onSelect(entries[0]);
        }
    }

    renderEntries = () => {
        const resultEntries: any[] = [];
        const {
            entries,
            onSelect
        } = this.props;

        entries.forEach(item => {
            resultEntries.push(
                <ResultEntry key={item.value} onClick={(e: any) => onSelect(item)}>
                    {item.label}
                </ResultEntry>
            );
        });

        return resultEntries;
    }

    render() {
        const {
            placeholder,
            open,
            selected
        } = this.props;

        return (
            <PowerSelectContainer>
                <SelectTrigger display={open} onClick={this.handleOnTriggerClick}>
                    <Placeholder selected={selected !== null}>
                        {selected === null ? placeholder : selected?.label}
                    </Placeholder>
                    <StatusIcon
                        className="ember-power-select-status-icon"
                        display={open}
                        icon={arrow}
                    />
                </SelectTrigger>
                <Dropdown display={open}>
                    <SearchInputField ref={this.inputRef} type={'search'} placeholder={placeholder} onChange={this.handleOnInputChange} />
                    <ResultList>
                        {this.renderEntries()}
                    </ResultList>
                </Dropdown>
            </PowerSelectContainer>
        );
    }
}