import React, { RefObject } from 'react'
import { Navigate } from 'react-router-dom'
import styled from 'styled-components'

import RegionRole from '../models/RegionRole'
import CustomerService from '../services/CustomerService'
import { RegionRoleService } from '../services/RegionRoleService'
import Button from './Button'
import Captcha from './Captcha'
import { Heading } from './Heading'
import Input from './Input'
import Select from './Select'

const StyledLeadWrapper = styled.div`
  padding: 0 22.5% 0 12.5%;

  @media screen and (max-width: ${(props) => props.theme.breakpointMobile}) {
    & {
      padding: 0 25px;
    }
  }
`

const StyledContent = styled.p`
  color: ${(props) => props.theme.grey02};
  font-size: 21px;
  font-size: 1.3125rem;
  margin: 0 0 79px;
  line-height: 27px;
`

const StyledForm = styled.form<any>`
  padding: 20px 0;
  display: ${(props) => (props.show ? 'block' : 'none')};
`

const StyledFormBody = styled.div``

const StyledFormActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
`

const StyledFormControl = styled.div`
  margin-bottom: 20px;
`

const StyledErrorText = styled.p`
  color: ${(props) => props.theme.accent};
  font-size: 18px;
  line-height: 26px;
`

const StyledLink = styled.a`
  color: ${(props) => props.theme.accent};
`

type State = {
  showForm: boolean
  regionRole?: RegionRole | null
  subjectOpen: boolean
  selectedSubject: any
  subjects: any
  privacy: boolean
  showPrivacyError: boolean
  newsletter: boolean
  navigateTo: string | null
  showSubjectError: boolean
  showWaiting: boolean
  showError: boolean
  showSuccess: boolean
  showCaptcha: boolean
  captchaResult: string | null
  captchaToken: string | null
  captchaQuestion: string
}

type Props = {
  zipId: string
  regionRoleId: string
  kind: string
  url: string
}

export default class LeadForm extends React.Component<Props, State> {
  formRef: RefObject<HTMLFormElement>
  subjects: any[] = []

  constructor(props: Props) {
    super(props)
    this.subjects =
      this.props.kind === 'phone'
        ? [{ label: 'Rückruf', value: 0 }]
        : [
            { label: 'Anfrage', value: 0 },
            { label: 'Beschwerde', value: 1 },
            { label: 'Sonstiges', value: 3 },
          ]

    this.state = {
      showForm: true,
      subjectOpen: false,
      selectedSubject: null,
      subjects: this.subjects,
      privacy: false,
      showPrivacyError: false,
      newsletter: false,
      navigateTo: null,
      showSubjectError: false,
      showWaiting: false,
      showError: false,
      showSuccess: false,
      captchaResult: null,
      showCaptcha: false,
      captchaToken: null,
      captchaQuestion: '',
    }
    this.handleOnSelectTriggerClicked = this.handleOnSelectTriggerClicked.bind(this)
    this.handleOnSelect = this.handleOnSelect.bind(this)
    this.handleOnSelectInputChange = this.handleOnSelectInputChange.bind(this)
    this.handlePrivacyChange = this.handlePrivacyChange.bind(this)
    this.handleNewsletterChange = this.handleNewsletterChange.bind(this)
    this.handleSubmitForm = this.handleSubmitForm.bind(this)
    this.handleCaptchaSubmit = this.handleCaptchaSubmit.bind(this)
    this.formRef = React.createRef()
  }

  async componentDidMount() {
    const { regionRoleId } = this.props
    const regionRole = await RegionRoleService.findById(regionRoleId)
    if (regionRole === null) {
      return
    }
    this.setState({ regionRole: regionRole })
  }

  handleOnSelectTriggerClicked() {
    this.setState({ subjectOpen: !this.state.subjectOpen })
  }

  handleOnSelect(selectedValue: any) {
    this.setState({ selectedSubject: selectedValue, subjectOpen: false })
  }

  handleOnSelectInputChange(e: any) {
    const subjects = this.subjects.filter((subject: any) =>
      subject.label.toLowerCase().includes(e.target.value.toLowerCase())
    )
    this.setState({ subjects: subjects })
  }

  handlePrivacyChange() {
    this.setState({ privacy: !this.state.privacy })
  }

  handleNewsletterChange() {
    this.setState({ newsletter: !this.state.newsletter })
  }

  handleSubmitForm(e: any) {
    e.preventDefault()
    return this.sendData()
  }

  async sendData() {
    const form = this.formRef.current

    if (!form) {
      // something went totally wrong :D
      return
    }

    // validate
    let isPrivacyValid = true
    let isSubjectValid = true
    const { privacy, selectedSubject, newsletter, regionRole } = this.state

    if (!privacy) {
      isPrivacyValid = false
    }

    if (!selectedSubject) {
      isSubjectValid = false
    }

    this.setState({
      showPrivacyError: !isPrivacyValid,
      showSubjectError: !isSubjectValid,
    })

    if (!(isPrivacyValid && isSubjectValid)) {
      return false
    }

    // serialize form
    const formData = new FormData(form)
    const data = new URLSearchParams()
    for (const pair of formData.entries()) {
      data.append('lead[' + pair[0] + ']', pair[1].toString())
    }
    data.append('lead[role]', regionRole?.role?.id?.toString() as string)
    data.append('lead[subject]', selectedSubject.label)
    data.append('lead[newsletter]', newsletter ? '1' : '0')
    data.append('lead[zip]', this.props.zipId)
    const customer = await CustomerService.getCustomer()
    data.append('lead[customer]', customer?.id as string)
    data.append('lead[widget_identifier]', process.env.REACT_APP_WIDGET_IDENTIFIER as string)
    data.append('lead[widget_url]', this.props.url)

    // build captcha query if result provided
    const { captchaToken, captchaResult } = this.state

    let captchaQuery = ''
    if (captchaToken && captchaResult) {
      captchaQuery = `?token=${captchaToken}&value=${captchaResult}`
    }

    // send lead to api
    this.setState({ showWaiting: true, showCaptcha: false })
    const response = await fetch(process.env.REACT_APP_API_URL + '/api/leads' + captchaQuery, {
      body: data,
      method: 'POST',
    })

    // check if captcha needed
    if (response.status === 499) {
      // show captcha
      const json = await response.json()
      this.setState({
        showCaptcha: true,
        captchaToken: json.captcha.token,
        captchaQuestion: json.captcha.label,
        captchaResult: null,
        showWaiting: false,
      })
      return false
    }

    if (response.status === 200 || response.status === 201) {
      // continue
      this.setState({
        showWaiting: false,
        showSuccess: true,
      })
      return false
    }

    this.setState({
      showWaiting: false,
      showError: true,
    })

    return false
  }

  handleCaptchaSubmit(result: string) {
    this.setState({ captchaResult: result }, () => {
      this.sendData()
    })
  }

  render() {
    const {
      showForm,
      regionRole,
      subjectOpen,
      selectedSubject,
      subjects,
      showPrivacyError,
      navigateTo,
      showSubjectError,
      showWaiting,
      showError,
      showSuccess,
      showCaptcha,
      captchaQuestion,
    } = this.state

    const { kind } = this.props

    if (navigateTo) {
      return <Navigate to={navigateTo} />
    }

    if (showError) {
      return (
        <StyledLeadWrapper>
          <Heading kind="h2">Es ist ein Fehler aufgetreten!</Heading>
          <p>
            Fehler! Das Formular konnte wegen technischer Fehler nicht abgesendet werden. Hier finden Sie die
            Kontaktdaten:
          </p>
          <p>
            E-Mail: <StyledLink href={'mailto:' + regionRole?.contact?.email}>{regionRole?.contact?.email}</StyledLink>
            <br />
            Telefon: <StyledLink href={'tel:' + regionRole?.contact?.tel}>{regionRole?.contact?.tel}</StyledLink>
            <br />
          </p>
        </StyledLeadWrapper>
      )
    }

    if (showSuccess) {
      return (
        <StyledLeadWrapper>
          <Heading kind="h2">Vielen Dank für Ihre Nachricht.</Heading>
          <p>
            {regionRole?.contact?.name}, {regionRole?.role?.nameExtern} für {regionRole?.region?.name} wurde
            benachricht.
          </p>
          <p>
            Mit der Empfangsbestätigung, dass Ihre E-Mail bei uns eingegangen ist, erhalten Sie weitere Kontaktdaten
            Ihres Ansprechpartners, um mögliche Anhänge mitzusenden.
          </p>
          <Button
            grey
            outlined
            textSize="small"
            label="Neue Suche starten"
            onClick={() => this.setState({ navigateTo: '/' })}
          />
        </StyledLeadWrapper>
      )
    }

    return (
      <StyledLeadWrapper>
        {showForm && <Heading kind="h1">Ihr regionaler Ansprechpartner</Heading>}
        {showForm && regionRole && regionRole.contact && kind === 'mail' && (
          <Heading kind="h2">Senden Sie {regionRole.contact.name} eine Nachricht</Heading>
        )}
        {showForm && regionRole && regionRole.contact && kind === 'phone' && (
          <Heading kind="h2">Senden Sie {regionRole.contact.name} eine Bitte um Rückruf</Heading>
        )}
        {showForm && (
          <StyledContent>
            Und erhalten Sie mit der Empfangsbestätigung weitere Kontaktdaten wie Telefonnummer und E-Mailadresse.
          </StyledContent>
        )}
        {showWaiting && <p>Senden...</p>}
        {showCaptcha && <Captcha question={captchaQuestion} onSubmit={this.handleCaptchaSubmit} />}
        <StyledForm ref={this.formRef} show={showForm && !showWaiting && !showCaptcha} onSubmit={this.handleSubmitForm}>
          <StyledFormBody>
            <StyledFormControl>
              <Select
                entries={subjects}
                open={subjectOpen}
                placeholder="Betreff *"
                selected={selectedSubject}
                onChange={this.handleOnSelectInputChange}
                onSelect={this.handleOnSelect}
                onTriggerClicked={this.handleOnSelectTriggerClicked}
              />
              {showSubjectError && <StyledErrorText>Bitte Betreff angeben</StyledErrorText>}
            </StyledFormControl>
            <StyledFormControl>
              <Input required label="Ihr Name" name="lastname" type="text" onChange={() => {}} />
            </StyledFormControl>
            <StyledFormControl>
              <Input required label="Ihre E-Mail" name="email" type="email" onChange={() => {}} />
            </StyledFormControl>
            <StyledFormControl>
              <Input required label="Telefon" name="tel" type="tel" onChange={() => {}} />
            </StyledFormControl>
            {kind === 'mail' && (
              <StyledFormControl>
                <Input required label="Firma" name="company" type="text" onChange={() => {}} />
              </StyledFormControl>
            )}
            {kind === 'mail' && (
              <StyledFormControl>
                <Input required label="Funktion" name="job" type="text" onChange={() => {}} />
              </StyledFormControl>
            )}
            <StyledFormControl>
              <Input required label="Nachricht" name="message" type="textarea" onChange={() => {}} />
            </StyledFormControl>
            <div style={{ margin: '40px 0px' }}>* sind Pflichtfelder</div>
          </StyledFormBody>
          <StyledFormControl>
            <Input
              label={'Ich möchte den monatlichen Schindler Newsletter abonnieren.'}
              type="checkbox"
              name=""
              onChange={this.handleNewsletterChange}
            />
          </StyledFormControl>
          <StyledFormControl>
            <Input
              label={
                "Ich habe die <a href='" +
                process.env.REACT_APP_TERMS_PAGE +
                "'>Datenschutzerklärung</a> zur Kenntnis genommen. Ich stimme zu, dass meine Angaben zur Kontaktaufnahme und für Rückfragen gespeichert werden."
              }
              type="checkbox"
              name=""
              onChange={this.handlePrivacyChange}
            />
            {showPrivacyError && (
              <StyledErrorText>Bitte nehmen Sie unsere Datenschutzerklärung zur Kenntnis.</StyledErrorText>
            )}
          </StyledFormControl>
          <StyledFormActions>
            <Button
              grey
              textSize="small"
              label="Neue Suche starten"
              type="button"
              onClick={() => this.setState({ navigateTo: '/' })}
            />
            <Button grey textSize="small" label="Absenden" type="submit" onClick={() => {}} />
          </StyledFormActions>
        </StyledForm>
      </StyledLeadWrapper>
    )
  }
}
