import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import haushahnLogo from '../assets/images/haushahn-logo.png';
import schindlerLogo from '../assets/images/schindler-logo.jpg';
import Customer from '../models/Customer';
import CustomerService from '../services/CustomerService';

const StyledHeader = styled.header<any>`
    width: 100%;
    height: 500px;
    background: url(${(props) => props.image});
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: center 32%!important;
    margin-bottom: 80px;
`;

const StyledNavbar = styled.nav`
    width: 75vw;
    height: 148px;
    background: ${props => props.theme.white};
    margin: 0 auto;
    display: flex;
    justify-content: center;
    -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);

    @media only screen and (max-width: ${(props) => props.theme.breakpointTablet}) {
        & {
            width: 100vw;
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.breakpointMobile}) {
        & {
            justify-content: center;
        }
    }

    
`;

const StyledMenu = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`;

const StyledMenuEntry = styled.li`
    display: inline;
    align-self: center;
    list-style: none;

    &:nth-child(2) {
        min-width: 123px;
        margin: 0 20vw 0 8px;
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
        padding: 0 1.9vw 0 0;
    }

    @media screen and (max-width: ${props => props.theme.breakpointMobile}) {
        &:nth-child(2) {
            min-width: 123px;
            margin: 0 16vw 0 8px;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
            display: none;
        }
    }
`;

const StyledLogoLeft = styled.img`
    width: 65px;
    height: auto;
    margin: 0 auto;
`;

const StyledLogoDescriptionLeft = styled.span`
    font-size: 12px;
    font-size: .75rem;
    color: ${(props) => props.theme.grey02};
`;

const StyledLink = styled.a`
    align-self: center;
    justify-self: flex-end;
    color: ${(props) => props.theme.grey02};
    font-size: 16px;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
`;

const StyledLogoRight = styled.img`
    width: 75px;
    height: auto;
    margin: 0 auto;
`;

type State = {
    customer?: Customer | null,
    widgetConfiguration?: any
}

type Props = {

};

type MenuEntryProps = {
    label: string,
    href?: string | "",
    selector?: string | ""
}

const MenuEntry = (props: MenuEntryProps) => {
    const navigate = useNavigate();
    
    return (
        <StyledMenuEntry>
            <StyledLink href={props.href} onClick={() => {
                const selector = props.selector;
                if(!selector) {
                    return;
                }
                navigate('/');
                setTimeout(() => {
                    const targetContainer = document.querySelector(selector);
                    window.scroll({
                        top: targetContainer?.getBoundingClientRect().top as number + window.scrollY,
                        behavior: 'smooth'
                    });
                }, 250);
            }}>{props.label}</StyledLink>
        </StyledMenuEntry>
    );
}

export default class Header extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        const customer = await CustomerService.getCustomer();
        if (customer === null) {
            return;
        }
        const widgetConfiguration = JSON.parse(customer.widgetConfig);
        this.setState({
            customer: customer,
            widgetConfiguration: widgetConfiguration
        });
    }

    render() {

        const {
            customer,
            widgetConfiguration
        } = this.state;

        return (
            <StyledHeader image={customer?.logo}>
                <StyledNavbar>
                    <StyledMenu>
                        <StyledMenuEntry>
                            {customer &&
                                <StyledLogoLeft src={customer.logo} />}
                        </StyledMenuEntry>
                        <StyledMenuEntry>
                            {customer &&
                                <StyledLogoDescriptionLeft>
                                    Ein Service von {process.env.REACT_APP_COMPANY}<br />
                                    für {customer.publicName}
                                </StyledLogoDescriptionLeft>}
                        </StyledMenuEntry>
                        {widgetConfiguration && widgetConfiguration.malfunction &&
                            <MenuEntry label='Störungsmeldung' selector='#stoerungsmeldung' />}
                        {widgetConfiguration && widgetConfiguration.malfunction &&
                            <StyledMenuEntry>
                                <MenuEntry label='Ansprechpartner finden' selector='#region-search' />
                        </StyledMenuEntry>}
                        <StyledMenuEntry>
                            <StyledLink href={process.env.REACT_APP_HOMEPAGE} target="_blank">{process.env.REACT_APP_HOMEPAGE_LINKTEXT}</StyledLink>
                        </StyledMenuEntry>
                        <StyledMenuEntry>
                            <StyledLogoRight src={process.env.REACT_APP_TEMPLATE_KEY === "charm" ? haushahnLogo : schindlerLogo} alt="Schindler-Logo" />
                        </StyledMenuEntry>
                    </StyledMenu>
                </StyledNavbar>
            </StyledHeader>
        );
    }
}
