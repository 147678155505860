import { Email, Phone } from '@mui/icons-material';
import React from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";

import CaptchaComponent from '../components/Captcha';
import Captcha from "../models/Captcha";
import Customer from "../models/Customer";
import Region from "../models/Region";
import RegionRole from "../models/RegionRole";
import Role from "../models/Role";
import ServiceCenter from "../models/ServiceCenter";
import Zip from "../models/Zip";
import CustomerService from "../services/CustomerService";
import { RegionRoleService } from "../services/RegionRoleService";
import RoleService from "../services/RoleService";
import { ServiceCenterService } from "../services/ServiceCenterService";
import { ZipService } from "../services/ZipService";
import { Heading } from "./Heading";
import PowerSelect from "./PowerSelect";
import Select from "./Select";

type Props = {}

type State = {
    widgetConfiguration?: any | null,
    customer?: Customer | null,
    showWaiting: boolean,
    results: Zip[],
    selectedZip: Zip | null,
    term: string,
    servicecenter: ServiceCenter | null,
    regionRoles: RegionRole[],
    displayedRegionRoles: RegionRole[],
    captcha: Captcha | null,
    open: boolean,
    navigateTo: string | null,
    sortedRoles: Role[],
    filteredRoles: Role[],
    selectedRole?: Role | null;
    roleSelectOpen: boolean,
}

const StyledRegionSearch = styled.div`
    padding: 0 18% 0 12.5%;
`;

const StyledText = styled.p`
    color: ${props => props.theme.grey02};
    font-size: 21px;
    font-size: 1.3125rem;
    margin: 0 0 79px;
`;

const StyledSearchWrapper = styled.div`
    display: grid;
    grid-template-columns: 280px 450px;
    margin-bottom: 50px;

    @media screen and (max-width: ${props => props.theme.breakpointMobile}) {
        & {
            grid-template-columns: 80vw;
            grid-row-gap: 20px;
        }
    }
`;

const StyledResultWrapper = styled.div`
    
`;

const StyledResultsTotal = styled.p`
    color: ${props => props.theme.grey06};
    font-size: 16px;
    font-size: 1rem;
    margin: 50px 0 30px;
`;

const StyledCardsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const StyledCard = styled.div`
    width: 276px;
    height: 215px;
    background: ${props => props.theme.white};
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    border-radius: 0;
    margin: 0 30px 30px 0;

    &:hover {
        transition: box-shadow .35s;
        box-shadow: 0 8px 17px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    }
`;

const StyledCardContent = styled.div`
    height: 154px;
    display: inline-block;
`;

const StyledContactName = styled.p`
    margin: 20px 0 8px 20px;
    font-size: 21px;
    font-size: 1.3125rem;
    color: ${props => props.theme.accent};
`;

const StyledRoleName = styled.p`
    margin: 0 10px 0 20px;
    background-color: ${props => props.theme.grey05};
    font-size: 15px;
    font-size: .9375rem;
    padding: 6px 10px;
    display: inline-block;
    border-radius: 15px;
`;

const StyledDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.grey08};
`;

const StyledCardFooter = styled.div`
    height: 60px;
    text-align: center;
    padding-top: 20px;
`;

const StyledCardLink = styled.a`
    text-decoration: none!important;
    color: ${props => props.theme.accent};
    cursor: pointer;
`;

const StyledWaiting = styled.p`

`;

export default class RegionSearch extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            showWaiting: false,
            results: [],
            selectedZip: null,
            term: "",
            servicecenter: null,
            regionRoles: [],
            displayedRegionRoles: [],
            captcha: null,
            open: false,
            navigateTo: null,
            sortedRoles: [],
            filteredRoles: [],
            roleSelectOpen: false,
            selectedRole: null
        };
        this.handleOnTriggerClick = this.handleOnTriggerClick.bind(this);
        this.handleOnInputChange = this.handleOnInputChange.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.handleOnCaptchaSubmit = this.handleOnCaptchaSubmit.bind(this);
        this.handleOnRoleInputChange = this.handleOnRoleInputChange.bind(this);
        this.handleOnRoleSelect = this.handleOnRoleSelect.bind(this);
        this.handleOnRoleTriggerClicked = this.handleOnRoleTriggerClicked.bind(this);
    }

    async componentDidMount() {
        const customer = await CustomerService.getCustomer();
        if (customer != null) {
            const widgetConfiguration = JSON.parse(customer.widgetConfig);
            this.setState({
                customer: customer,
                widgetConfiguration: widgetConfiguration
            });

            const roles = await RoleService.getSortedRolesByCustomer(widgetConfiguration.customer);
            if (roles != null) {
                this.setState({ sortedRoles: roles, filteredRoles: roles });
            }
        }
    }

    handleOnTriggerClick() {
        this.setState({
            open: !this.state.open
        });
    }

    handleOnCaptchaSubmit(result: String) {
        const { captcha } = this.state;
        if (captcha !== null) {
            captcha.result = result;
        }

        this.setState({ captcha: captcha }, this.loadRegionData);
    }

    handleOnInputChange(e: any) {
        // validate search term
        if (!e.target.value || e.target.value.length < 3) {
            this.setState({ results: [] });
            return;
        }

        const term = e.target.value;
        this.setState({ term: term })

        this.search(term);
    }

    async loadRegionData() {
        const {
            selectedZip,
            captcha
        } = this.state;

        const result = await RegionRoleService.getRegionRolesOrCaptcha(
            captcha,
            1,
            1,
            selectedZip?.id as string
        );

        if (result instanceof Captcha) {
            this.setState({
                regionRoles: [],
                displayedRegionRoles: [],
                servicecenter: null,
                captcha: result,
                open: false
            });

        } else {
            let servicecenter: ServiceCenter | null = null;

            if (result.length > 0) {
                servicecenter = await ServiceCenterService.findOneByRegion(result[0].region as Region);
            }

            this.setState({
                regionRoles: result,
                displayedRegionRoles: result,
                servicecenter: servicecenter,
                captcha: null,
                open: false
            });
        }
    }

    async search(term: string) {
        this.setState({ showWaiting: true });

        // query api
        const zips = await ZipService.autocomplete(term);

        if (term.length === 5 && zips.length === 1) {
            // auto select this zip
            this.setState({
                showWaiting: false,
                selectedZip: zips[0],
                results: []
            }, () => {
                this.loadRegionData();
            });
        } else {
            // set results
            this.setState({
                showWaiting: false,
                selectedZip: null,
                results: zips
            });
        }
    }

    handleOnSelect(zip: Zip) {
        this.setState({
            selectedZip: zip,
            results: []
        }, () => {
            this.loadRegionData();
        });
    }

    handleOnRoleInputChange(e: any) {
        this.setState({ term: e.target.value });
        const filteredRoles = this.state.sortedRoles.filter((role: Role) => role.nameExtern.toLowerCase().includes(e.target.value.toLowerCase()));
        this.setState({ filteredRoles: filteredRoles });
    }

    handleOnRoleSelect(item: { label: string, value: Role }) {
        const displayedRoles: RegionRole[] = this.state.regionRoles.filter((regionRole: RegionRole) => regionRole.role?.nameExtern.toLowerCase().includes(item.value.nameExtern.toLowerCase()))
        this.setState({
            selectedRole: item.value as Role,
            roleSelectOpen: false,
            displayedRegionRoles: displayedRoles
        });
    }

    handleOnRoleTriggerClicked(e: any) {
        this.setState({ roleSelectOpen: !this.state.roleSelectOpen });
    }

    render() {

        const {
            customer,
            widgetConfiguration,
            open,
            selectedZip,
            results,
            showWaiting,
            captcha,
            filteredRoles,
            roleSelectOpen,
            selectedRole,
            displayedRegionRoles,
            navigateTo
        } = this.state;

        if (navigateTo) {
            return <Navigate to={navigateTo} />
        }

        return (
            <StyledRegionSearch id="region-search">
                {widgetConfiguration?.headline && <Heading kind="h1">{widgetConfiguration.headline}</Heading>}
                {widgetConfiguration?.subline && <Heading kind="h2">{widgetConfiguration.subline}</Heading>}
                {widgetConfiguration?.subtext && <StyledText>{widgetConfiguration.subtext}</StyledText>}
                <StyledSearchWrapper>
                    <PowerSelect
                        open={open}
                        zipSelected={selectedZip}
                        searchPlaceholder="PLZ"
                        loadingMessage="Orte werden geladen"
                        noMatchesMessage="Es wurden keine Orte gefunden"
                        searchMessage="Tippen Sie eine PLZ oder Stadt ein"
                        placeholder="PLZ"
                        results={results}
                        showWaiting={showWaiting}
                        onTriggerClicked={this.handleOnTriggerClick}
                        onSubmit={() => { }}
                        onChange={this.handleOnInputChange}
                        onSelect={this.handleOnSelect}
                    />
                    {selectedZip &&
                        <Select
                            entries={filteredRoles.map((item, index) => {
                                return { "label": item.nameExtern, "value": item };
                            })}
                            open={roleSelectOpen}
                            placeholder="Funktion"
                            selected={selectedRole === null ? null : { "label": selectedRole?.nameExtern ?? '', "value": selectedRole }}
                            onChange={this.handleOnRoleInputChange}
                            onSelect={this.handleOnRoleSelect}
                            onTriggerClicked={this.handleOnRoleTriggerClicked}
                        />}
                </StyledSearchWrapper>
                {captcha &&
                    <div style={{ margin: '20px 0px' }}>
                        <CaptchaComponent
                            question={captcha.question ?? ""}
                            onSubmit={this.handleOnCaptchaSubmit}
                        />
                    </div>
                }
                {showWaiting &&
                    <StyledWaiting>Laden...</StyledWaiting>}
                {!captcha && selectedZip && !showWaiting &&
                    <StyledResultWrapper>
                        <StyledResultsTotal>{displayedRegionRoles.length} Ergebnisse</StyledResultsTotal>
                        <StyledCardsWrapper>
                            {displayedRegionRoles.map((item, i) => {
                                return (
                                    <StyledCard key={i}>
                                        <StyledCardContent>
                                            <StyledContactName>{item.contact?.name}</StyledContactName>
                                            <StyledRoleName>{item.role?.nameExtern}</StyledRoleName>
                                        </StyledCardContent>
                                        <StyledDivider></StyledDivider>
                                        <StyledCardFooter>
                                            <StyledCardLink title="E-Mail" onClick={() => this.setState({ navigateTo: `/form/${selectedZip.id}/${item.id}/mail` })}>
                                                <Email color="inherit" />
                                            </StyledCardLink>
                                            <StyledCardLink title="Rückruf" onClick={() => this.setState({ navigateTo: `/form/${selectedZip.id}/${item.id}/phone` })}>
                                                <Phone color="inherit" />
                                            </StyledCardLink>
                                        </StyledCardFooter>
                                    </StyledCard>
                                );
                            })}
                        </StyledCardsWrapper>
                    </StyledResultWrapper>}

            </StyledRegionSearch>
        );
    }
}